import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector, useDispatch } from 'react-redux'
import io from 'socket.io-client'
import { Creators as authActions } from 'store/ducks/Auth'

export const SocketContext = React.createContext(null)

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    height: 30,
    background: 'rgba(255,0,0,0.6)',
    color: 'white',
    textAlign: 'center',
    width: 250,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    zIndex: 99999,
  },
})

function SocketProvider({ children }) {
  const [socket, setSocket] = useState(() => window.socket || null)
  const { data } = useSelector(state => state.auth)
  const isAuthenticated =
    data?.token || localStorage.getItem('FRIGOCENTER.ACCESS_TOKEN') || null
  const classes = useStyles()
  const dispatch = useDispatch()

  const loadSocket = () => {
    const accessToken = localStorage.getItem('FRIGOCENTER.ACCESS_TOKEN')
    if (!accessToken) {
      console.warn('Token de acesso ausente. Não conectando ao socket.')
      return
    }

    const con = io(process.env.REACT_APP_SOCKET_URL, {
      reconnection: true,
      reconnectionDelay: 5000,
      reconnectionDelayMax: 30000,
      query: {
        token: accessToken,
      },
      transports: ['websocket'],
    })

    con.on('connect', () => {
      NotificationManager.success('Socket conectado.')
      console.info(`Connected with socket ID: ${con.id}`)
      setSocket(con)
      window.socket = con
    })

    con.on('disconnect', () => {
      NotificationManager.error('Socket desconectado.')
      setSocket(null)
      window.socket = null
    })

    con.on('connect_error', err => {
      console.error('Erro ao conectar socket:', err)
      NotificationManager.error('Erro ao conectar ao socket.')
      setSocket(null)
      window.socket = null
    })

    con.on('message', ({ type, message }) => {
      const returnType = type?.split(':')?.pop()
      if (returnType === 'error') {
        NotificationManager.error(message)
      } else if (returnType === 'success') {
        NotificationManager.success(message)
      }
    })
  }

  useEffect(() => {
    const accessToken = localStorage.getItem('FRIGOCENTER.ACCESS_TOKEN')
    if (accessToken && !isAuthenticated) {
      dispatch(authActions.restoreSession(accessToken))
    }
  }, [dispatch, isAuthenticated])

  useEffect(() => {
    if (isAuthenticated && !socket) {
      loadSocket()
    } else if (!isAuthenticated && socket) {
      socket.disconnect()
      setSocket(null)
      window.socket = null
    }
  }, [isAuthenticated, socket])

  return (
    <SocketContext.Provider value={{ socket, loadSocket }}>
      {children}
      {isAuthenticated && !socket && (
        <div className={classes.root}>
          <span>SOCKET NÃO CONECTADO</span>
        </div>
      )}
    </SocketContext.Provider>
  )
}

export default SocketProvider
