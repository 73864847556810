import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import asyncComponent from '../../util/asyncComponent'
import { getTypeUser } from '../../util'

const Routes = () => (
  <Switch>
    <Route
      path="/dashboard"
      component={asyncComponent(() => import('./Subscription/Overview'))}
      exact
    />

    <Route
      path="/sites/:siteId/new-equipment"
      component={asyncComponent(() => import('./admin/Equipments/form'))}
      exact
    />
    <Route
      path="/sites/new"
      component={asyncComponent(() => import('./admin/Customers/Form'))}
      exact
    />
    <Route
      path="/sites/settings"
      component={asyncComponent(() => import('./admin/Customers/List'))}
      exact
    />
    <Route
      path="/sites/settings/:siteId"
      component={asyncComponent(() => import('./admin/Customers/Form'))}
      exact
    />
    <Route
      path="/sites/:siteId/:siteTab?/"
      component={asyncComponent(() => import('./dashboard'))}
      exact
    />
    <Route
      path="/sites"
      component={asyncComponent(() => import('./dashboard/CustomerList'))}
      exact
    />

    <Route
      path="/equipments/:equipmentId/new-variable"
      component={asyncComponent(() => import('./Variable/Create'))}
      exact
    />
    <Route
      path="/equipments/settings/:equipmentId"
      component={asyncComponent(() => import('./admin/Equipments/form'))}
      exact
    />
    <Route
      path="/equipments/:equipmentId/:tabName?"
      component={asyncComponent(() => import('./dashboard/equipamentDetail'))}
    />

    <Route
      path="/variables/:variableId/new-detour"
      component={asyncComponent(() => import('./Variable/Detour'))}
      exact
    />
    <Route
      path="/variables/:variableId/new-alarm"
      component={asyncComponent(() => import('./Variable/Alarm'))}
      exact
    />
    <Route
      path="/variables/settings/:variableId"
      component={asyncComponent(() => import('./Variable/Update'))}
    />

    <Route
      path="/alarms/settings/:alarmId"
      component={asyncComponent(() => import('./Variable/AlarmEdit'))}
      exact
    />
    <Route
      path="/detours/settings/:detourId"
      component={asyncComponent(() => import('./Variable/DetourEdit'))}
      exact
    />

    <Route
      path="/account"
      component={asyncComponent(() => import('./MyUser'))}
    />

    <Route
      path="/charts/new"
      component={asyncComponent(() =>
        import('./Chart/components/ModelRegister'),
      )}
      exact
    />

    <Route
      path="/charts/settings/:chartId"
      component={asyncComponent(() =>
        import('./Chart/components/ModelRegister'),
      )}
      exact
    />

    <Route
      path="/charts/:variablesIds/plot"
      component={asyncComponent(() => import('./Plot'))}
      exact
    />

    <Route
      path="/charts/:chartId"
      component={asyncComponent(() => import('./Plot'))}
      exact
    />

    <Route
      path="/charts"
      component={asyncComponent(() => import('./Chart/components/ModelList'))}
    />

    {(getTypeUser().includes('root') ||
      getTypeUser().includes('admin') ||
      getTypeUser().includes('engineer')) && (
      <Route
        path="/users/new"
        component={asyncComponent(() =>
          import('./User/components/UserRegister'),
        )}
        exact
      />
    )}
    {(getTypeUser().includes('root') ||
      getTypeUser().includes('admin') ||
      getTypeUser().includes('engineer')) && (
      <Route
        path="/users/settings/:userId"
        component={asyncComponent(() =>
          import('./User/components/UserRegister'),
        )}
        exact
      />
    )}
    {(getTypeUser().includes('root') ||
      getTypeUser().includes('admin') ||
      getTypeUser().includes('engineer')) && (
      <Route
        path="/users/settings"
        component={asyncComponent(() => import('./User/components/UserList'))}
      />
    )}

    {(getTypeUser().includes('root') ||
      getTypeUser().includes('admin') ||
      getTypeUser().includes('engineer')) && (
      <Route
        path="/teams/new"
        component={asyncComponent(() =>
          import('./User/components/TeamRegister'),
        )}
        exact
      />
    )}
    {(getTypeUser().includes('root') ||
      getTypeUser().includes('admin') ||
      getTypeUser().includes('engineer')) && (
      <Route
        path="/teams/settings/:teamId"
        component={asyncComponent(() =>
          import('./User/components/TeamRegister'),
        )}
        exact
      />
    )}

    {(getTypeUser().includes('root') ||
      getTypeUser().includes('admin') ||
      getTypeUser().includes('engineer')) && (
      <Route
        path="/teams/settings"
        component={asyncComponent(() => import('./User/components/TemList'))}
      />
    )}

    {(getTypeUser().includes('root') ||
      getTypeUser().includes('admin') ||
      getTypeUser().includes('engineer') ||
      getTypeUser().includes('maintenance')) && (
      <Route
        path="/monitoring/detours"
        component={asyncComponent(() => import('./Monitoring/Detour'))}
        exact
      />
    )}
    {(getTypeUser().includes('root') ||
      getTypeUser().includes('admin') ||
      getTypeUser().includes('engineer') ||
      getTypeUser().includes('maintenance')) && (
      <Route
        path="/monitoring/detours/:detourId/status"
        component={asyncComponent(() => import('./Monitoring/StatusHistory'))}
        exact
      />
    )}

    {(getTypeUser().includes('root') ||
      getTypeUser().includes('admin') ||
      getTypeUser().includes('engineer') ||
      getTypeUser().includes('maintenance')) && (
      <Route
        path="/monitoring/alarms"
        component={asyncComponent(() => import('./Monitoring/Alarm'))}
        exact
      />
    )}
    {(getTypeUser().includes('root') ||
      getTypeUser().includes('admin') ||
      getTypeUser().includes('engineer') ||
      getTypeUser().includes('maintenance')) && (
      <Route
        path="/monitoring/alarms/:alarmId/status"
        component={asyncComponent(() => import('./Monitoring/StatusHistory'))}
        exact
      />
    )}

    {(getTypeUser().includes('root') ||
      getTypeUser().includes('admin') ||
      getTypeUser().includes('engineer') ||
      getTypeUser().includes('maintenance')) && (
      <Route
        path="/monitoring/alarms/history"
        component={asyncComponent(() => import('./Monitoring/History'))}
        exact
      />
    )}

    {(getTypeUser().includes('root') ||
      getTypeUser().includes('admin') ||
      getTypeUser().includes('engineer') ||
      getTypeUser().includes('maintenance')) && (
      <Route
        path="/monitoring/insights"
        component={asyncComponent(() => import('./Monitoring/Insights'))}
        exact
      />
    )}

    {(getTypeUser().includes('root') ||
      getTypeUser().includes('admin') ||
      getTypeUser().includes('engineer')) && (
      <>
        <Route
          path="/templates/:templateId/new-variable"
          component={asyncComponent(() => import('./Variable/Create'))}
          exact
        />
        <Route
          path="/templates/new"
          component={asyncComponent(() => import('./Templates/Form'))}
          exact
        />
        <Route
          path="/templates/settings/:templateId"
          component={asyncComponent(() => import('./Templates/Form'))}
          exact
        />
        <Route
          path="/templates/settings"
          component={asyncComponent(() => import('./Templates/List'))}
          exact
        />
      </>
    )}

    {/* <Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/> */}
  </Switch>
)

export default withRouter(Routes)
