import { combineReducers } from 'redux'

import auth from './Auth'
import common from './Common'
import settings from './Setting'
import user from './User'
import team from './Team'
import variable from './Variable'
import alarm from './Alarm'
import detour from './Detour'
import gasLack from './GasLackInsight'
import customer from './Customer'
import equipament from './Equipament'
import template from './Template'
import mold from './Mold'
import variableData from './VariableData'
import variableConfig from './VariableConfig'
import statuses from './Statuses'
import { alarmData, historyData } from './AlarmData'
import dashboard from './Dashboard'
import intersection from './Intersection'
import subscription from './Subscription'

const rootReducer = combineReducers({
  auth,
  common,
  settings,
  user,
  team,
  variable,
  alarm,
  detour,
  gasLack,
  customer,
  equipament,
  template,
  mold,
  variableData,
  variableConfig,
  statuses,
  alarmData,
  historyData,
  dashboard,
  intersection,
  subscription,
})

export default rootReducer
